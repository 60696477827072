<template>
  <div class="modal fade" id="uploadVideo">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Subir información</h5>
          <button type="button" class="close" @click="toggle()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="file" id="video" @change="video" multiple />
          <p id="error-video" class="text-danger text-center mt-2"></p>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${progreso}%;`"
              :aria-valuenow="progreso"
              aria-valuemin="0"
              aria-valuemax="100"
            >{{progreso}}%</div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="toggle()"
          >Cancelar</button>
          <button type="button" class="btn btn-primary" @click="subir" v-if="!estado_upload">Cargar</button>
          <button class="btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Subiendo...
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      estado_upload: false,
      progreso: 0,
    };
  },
  computed: {
    evaluar() {
      return this.$store.getters.getEvaluacion;
    },
    participante() {
      return this.$store.getters.getUsuarioEvaluar;
    },
  },
  methods: {
    toggle() {
      $("#uploadVideo").modal("toggle");
    },
    async subir() {
      this.estado_upload = true;
      const formData = new FormData();
      let contador = 0;
      var ubicacion = `/plataforma holistica/${this.participante.fullname}/${this.evaluar.tipo_taller} - ${this.evaluar.fecha_taller}`;
      var Dropbox = require("dropbox").Dropbox;
      var dbx = new Dropbox({
        fetch: fetch,
        accessToken: process.env.VUE_APP_TOKEN_DROPBOX,
      });
      this.progreso = 0;
      let files = $("#video")[0].files;
      var that = this;
      for (let file of files) {
        const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
        if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
          // File is smaller than 150 Mb - use filesUpload API
          await this.uploadFile(ubicacion, file, contador);
        } else {
          // File is bigger than 150 Mb - use filesUploadSession* API
          const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

          var workItems = [];

          var offset = 0;

          while (offset < file.size) {
            var chunkSize = Math.min(maxBlob, file.size - offset);
            workItems.push(file.slice(offset, offset + chunkSize));
            offset += chunkSize;
          }

          const task = workItems.reduce((acc, blob, idx, items) => {
            if (idx == 0) {
              // Starting multipart upload of file
              return acc.then(function () {
                return dbx
                  .filesUploadSessionStart({ close: false, contents: blob })
                  .then((response) => response.session_id);
              });
            } else if (idx < items.length - 1) {
              // Append part to the upload session
              return acc.then(function (sessionId) {
                var cursor = { session_id: sessionId, offset: idx * maxBlob };
                return dbx
                  .filesUploadSessionAppendV2({
                    cursor: cursor,
                    close: false,
                    contents: blob,
                  })
                  .then(() => sessionId);
              });
            } else {
              // Last chunk of data, close session
              return acc.then(function (sessionId) {
                var cursor = {
                  session_id: sessionId,
                  offset: file.size - blob.size,
                };
                var commit = {
                  path: ubicacion.toLowerCase() + "/" + file.name,
                  mode: "add",
                  autorename: true,
                  mute: false,
                };
                return dbx.filesUploadSessionFinish({
                  cursor: cursor,
                  commit: commit,
                  contents: blob,
                });
              });
            }
          }, Promise.resolve());

          task
            .then(function (result) {
              that.estado_upload = false;
              $("#video").val("");
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Se subio el video.",
              });
            })
            .catch(function (error) {
              console.error(error);
            });
        }
      }
      this.estado_upload = false;
      $("#video").val("");
      return false;
    },

    async uploadFile(path, file, contador) {
      var xhr = new XMLHttpRequest();
      let that = this;
      xhr.upload.onprogress = await function (evt) {
        var percentComplete = parseInt((100.0 * evt.loaded) / evt.total);
        that.progreso = percentComplete;
        // Upload in progress. Do something here with the percent complete.
      };

      xhr.onload = await function () {
        if (xhr.status === 200) {
          return { contador: contador++ };
          // Upload succeeded. Do something here with the file info.
        } else {
          return xhr.response || "Unable to upload file";
          // Upload failed. Do something here with the error.
        }
      };

      xhr.open("POST", "https://content.dropboxapi.com/2/files/upload");
      xhr.setRequestHeader(
        "Authorization",
        "Bearer " + process.env.VUE_APP_TOKEN_DROPBOX
      );
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.setRequestHeader(
        "Dropbox-API-Arg",
        this.httpHeaderSafeJson({
          path: `${path}/${file.name}`,
          mode: "add",
          autorename: true,
          mute: false,
        })
      );

      await xhr.send(file);
    },

    httpHeaderSafeJson(args) {
      return JSON.stringify(args).replace(
        /[\u007f-\uffff]/g,
        this.getSafeUnicode
      );
    },
    getSafeUnicode(c) {
      const unicode = `000${c.charCodeAt(0).toString(16)}`.slice(-4);
      return `\\u${unicode}`;
    },

    video(e) {
      let contador = 0;
      let extensiones = [
        "mp4",
        "mpeg",
        "mp3",
        "jpg",
        "png",
        "jpeg",
        "pdf",
        "docx",
      ];

      for (let index = 0; index < e.target.files.length; index++) {
        const element = e.target.files[index];
        let nombre = element.name;
        let posicion = nombre.indexOf(".");
        let extension = nombre.substring(posicion + 1);
        if (!extensiones.includes(extension)) {
          contador++;
        }
      }
      if (contador != 0) {
        $("#error-video").html(
          "Solo se admite video, audio, imagenes y documentos"
        );
        $("#video").val("");
      } else {
        $("#error-video").html("");
      }
    },
  },
};
</script>