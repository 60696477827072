<template>
  <section>
    <div class="mt-3 ml-2">
      <div class="card shadow">
        <div
          class="card-header py-1 bg-card cursor-pointer"
          @click="open_toggle('examen')"
        >
          Examenes
        </div>
        <div class="card-body" :class="cuerpo ? 'mostrar' : 'esconder'">
          <div class="row p-0 m-0">
            <div class="col-lg-3 p-0">
              <ul class="p-0">
                <li
                  class="list-items cursor-pointer mb-1"
                  v-for="e in evaluar.aspectos"
                  :key="e.id"
                >
                  <label class="m-0 p-1 cursor-pointer" @click="item(e)">{{
                    e.nombre
                  }}</label>
                  <span
                    v-if="e.definicion != ''"
                    class="float-right bg-light mt-1 mr-1 px-2 rounded-circle cursor-pointer"
                    @click="opendefinicion(e.definicion)"
                    >?</span
                  >
                </li>
              </ul>
            </div>
            <div
              class="col-lg-9"
              v-for="(j, index) in resultados"
              :key="index"
              v-show="j.user_id == participante.id && j.appearence_id == master"
            >
              <div class="evaluacion">
                <p class="m-0 pl-1">Evaluación</p>
                <p class="p-1" v-if="!j.edit">{{ j.evaluacion }}</p>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-else
                  v-model="j.evaluacion"
                ></textarea>
              </div>
              <div class="calificacion row m-0 mb-2">
                <div
                  class="align-content-center col-lg-2 d-flex justify-content-center m-0 p-0 px-1 py-1"
                >
                  <p class="m-0">Calificación</p>
                </div>
                <div class="m-0 col-lg-10 p-0 d-flex align-items-center">
                  <label class="mx-1 mb-0" v-for="i in 10" :key="i">
                    {{ i }}
                    <input
                      type="radio"
                      id="1"
                      :value="i"
                      v-model="j.puntaje"
                      :disabled="evaluar.estado_taller != 2 ? true : false"
                    />
                  </label>
                </div>
              </div>
              <div class="ejercicios">
                <p class="m-0 pl-1">Ejercicios de fortalecimiento</p>
                <select class="form-control mb-1" v-model="j.seleccionado">
                  <option value selected disabled>
                    Seleccione un ejercicio recomendado
                  </option>
                  <option
                    v-for="(m, index) in ejercicios"
                    :key="index"
                    :value="index + 1"
                  >
                    {{ m }}
                  </option>
                </select>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-end"
                v-if="!j.edit && evaluar.estado_taller == 2"
              >
                <button
                  class="m-0 mr-1 btn btn-sm btn-secondary"
                  @click="edit_item(index)"
                >
                  Editar
                </button>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="guardar(1, j)"
                  v-if="!guardar_calificacion"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando.....
                </button>
              </div>
              <div class="col-lg-12 d-flex justify-content-end mt-2" v-else>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="actualizar_item(index)"
                >
                  Actualizar
                </button>
              </div>
            </div>
            <div class="col-lg-12">
              <strong>Tu puntaje es</strong>
              : {{ inversa_puntaje_cuerpo() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Definicion -->
    <modalDefinicion :definicion="definicion" ref="modalDefinicion" />
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import modalDefinicion from "./modalDefinicion";
export default {
  props: ["participante", "fase", "posicion", "evaluar", "resultados"],
  data() {
    return {
      cuerpo: false,
      guardar_calificacion: false,
      definicion: "",
      ejercicios: [],
      master: "",
    };
  },
  components: {
    modalDefinicion,
  },
  methods: {
    actualizar_item(posicion) {
      this.$set(this.resultados[posicion], "edit", false);
    },

    open_toggle(posicion) {
      switch (posicion) {
        case "examen":
          this.cuerpo = !this.cuerpo;
          break;
        default:
          break;
      }
    },

    inversa_puntaje_cuerpo() {
      let suma = 0;
      let resultado = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (element.user_id == this.participante.id) {
          if (element.puntaje != "") {
            suma += element.puntaje;
          }
        }
      }
      if (suma != 0) {
        resultado = suma / this.resultados.length;
        return Math.round(resultado);
      }
      return 0;
    },

    edit_item(posicion) {
      this.$set(this.resultados[posicion], "edit", true);
    },

    item(data) {
      this.master = data.id;
      this.ejercicios = JSON.parse(data.ejercicios);
      const info = {
        user_id: this.participante.id,
        appearence_id: data.id,
        ejercicios: JSON.parse(data.ejercicios),
        metodologia: this.evaluar.metodologia,
        evaluacion: data.evaluacion,
      };
      this.$store.commit("addResultadoNuevoi", info);
    },

    opendefinicion(definicion) {
      this.definicion = definicion;
      this.$refs.modalDefinicion.toggle();
    },

    guardar(index, dato) {
      this.guardar_calificacion = true;
      let datos = {
        evaluacion: dato.evaluacion,
        mejora: dato.seleccionado,
        aspecto_id: dato.appearence_id,
        puntaje: dato.puntaje,
        metodologia: 1,
        usuario_id:
          dato.user_id != undefined ? dato.user_id : this.participante.id,
        taller_id:
          dato.workshop_id != undefined
            ? dato.workshop_id
            : this.evaluar.id_taller,
        id: dato.id != undefined ? dato.id : "",
      };
      if (
        datos.evaluacion == "" ||
        datos.mejora == "" ||
        datos.mejora == undefined ||
        datos.recomendacion == "" ||
        datos.puntaje == ""
      ) {
        this.guardar_calificacion = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Hay datos sin llenar!",
        });
      } else {
        if (datos.id == "") {
          let element = this;
          this.$store.dispatch("setResultado", datos).then((response) => {
            if (response.STATUS_CODE == 1) {
              for (let index = 0; index < element.resultados.length; index++) {
                const info = element.resultados[index];
                if (
                  info.user_id == response.DATA.user_id &&
                  info.appearence_id == response.DATA.appearence_id
                ) {
                  Object.assign(this.resultados[index], {
                    id: response.DATA.id,
                  });
                }
              }
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Se guardo la calificacion!",
              });
            } else {
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Erro al guardar la calificacion!",
              });
            }
          });
        } else {
          let element = this;
          datos._method = "put";
          this.$store.dispatch("updateResultado", datos).then((response) => {
            if (response.STATUS_CODE == 1) {
              for (let index = 0; index < element.resultados.length; index++) {
                const info = element.resultados[index];
                if (info.id == response.DATA.id) {
                  Object.assign({}, this.resultados[index], {
                    id: response.DATA.id,
                    evaluacion: response.DATA.evaluacion,
                    seleccionado: response.DATA.mejora,
                    puntaje: response.DATA.puntaje,
                    user_id: response.DATA.user_id,
                    appearence_id: response.DATA.appearence_id,
                    workshop_id: response.DATA.workshop_id,
                    mejora: info.mejora,
                  });
                  this.guardar_calificacion = false;
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });

                  Toast.fire({
                    icon: "success",
                    title: "Se actualizo la calificacion!",
                  });
                }
              }
            } else {
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Error al actualizar la calificacion!",
              });
            }
          });
        }
      }
    },
  },
};
</script>