<template>
  <div class="row m-0">
    <div class="col-lg-2 ml-0 pl-0" style="height: calc(100vh - 113px)">
      <ul class="item-taller pl-0 mb-0">
        <li class="cursor-pointer item-taller-activo text-center py-1">
          <a>Próximo Talleres</a>
        </li>
      </ul>
    </div>
    <loading class="col-lg-10 p-0" v-if="estadoloading" />
    <div
      class="col-lg-10"
      style="height: calc(100vh - 113px); overflow-y: scroll"
      v-else
    >
      <div v-for="taller in talleres" :key="taller.id">
        <div
          class="list-taller border-bottom border-right mt-2 mb-2"
          v-if="taller.estadoTaller.id != 3"
        >
          <p class="mb-0">
            <strong>Nombre empresa:</strong>
            {{ taller.empresa.nombre }}
          </p>
          <p class="mb-0">
            <strong>Tipo de taller:</strong>
            {{ taller.tipoTaller.nombre }}
          </p>
          <p class="mb-0">
            <strong>Número de participantes:</strong>
            {{ taller.cantPersona }}
          </p>
          <p class="mb-0">
            <strong>Formadores:</strong>
            {{
              taller.formadoruno.fullname != undefined
                ? taller.formadoruno.fullname
                : ""
            }}
            {{
              taller.formadordos.fullname != undefined
                ? ", " + taller.formadordos.fullname
                : ""
            }}
            {{
              taller.formadortres.fullname != undefined
                ? ", " + taller.formadortres.fullname
                : ""
            }}
            {{
              taller.formadorcuatro.fullname != undefined
                ? ", " + taller.formadorcuatro.fullname
                : ""
            }}
          </p>
          <p class="mb-0">
            <strong>Fecha:</strong>
            {{ taller.fecha }}
          </p>
          <p class="mb-0">
            <strong>Ciudad:</strong>
            {{ taller.ciudad.nombre }}
          </p>
          <p class="mb-0">
            <strong>Hora:</strong>
            {{ taller.hora }}
          </p>
          <p class="mb-0">
            <strong>Estado:</strong>
            <label
              class="mb-0 px-2 rounded text-white"
              :class="taller.estadoTaller.id == 1 ? 'bg-secondary' : 'bg-info'"
              >{{ taller.estadoTaller.nombre }}</label
            >
          </p>
          <div class="col-lg-12 mb-1 d-flex justify-content-end">
            <ul class="nav">
              <li class="nav-item">
                <p
                  class="nav-link m-0 py-0 text-white mx-1 rounded cursor-pointer"
                  :class="
                    taller.estadoTaller.id == 1 ? 'bg-success' : 'bg-info'
                  "
                  @click="evaluacion_open(taller.id)"
                >
                  {{
                    taller.estadoTaller.id == 1
                      ? "Crear Evaluaciones"
                      : "Continuar Evaluacion"
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loading from "@/components/Loading";
export default {
  name: "evaluaciones",
  data() {
    return {};
  },
  components: {
    loading,
  },
  computed: {
    talleres() {
      return this.$store.getters.getTalleres;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  methods: {
    evaluacion_open(id) {
      this.$router.push({ name: "realizar", params: { id: id } });
    },
  },
  mounted() {
    this.$store.dispatch("fetchTalleres");
  },
};
</script>
