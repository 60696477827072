<template>
  <div
    class="modal fade"
    id="modalDefinicion"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalScrollableTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle">Definicion</h5>
          <button type="button" class="close" @click="toggle">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">{{ definicion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    definicion: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggle() {
      $("#modalDefinicion").modal("toggle");
    },
  },
};
</script>