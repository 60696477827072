<template>
  <section>
    <loading v-if="estadoloading" />
    <div class="row m-0" v-else>
      <div class="col-lg-8">
        <div class="row bg-perfil">
          <div class="col-lg-4 d-flex justify-content-end align-items-center">
            <div class="position-relative cursor-pointer" @click="open_perfil">
              <img
                src="@/assets/img/avatar-hombre.svg"
                v-if="
                  participante.genero == 'M' &&
                  participante.foto == null &&
                  !cargando_foto
                "
                alt
                srcset
                width="120"
                height="120"
                class="rounded-circle"
              />
              <img
                :src="url + participante.foto"
                v-if="
                  participante.genero == 'M' &&
                  participante.foto != null &&
                  !cargando_foto
                "
                alt
                srcset
                width="120"
                height="120"
                class="rounded-circle"
              />
              <img
                src="@/assets/img/avatar-mujer.svg"
                v-if="
                  participante.genero == 'F' &&
                  participante.foto == null &&
                  !cargando_foto
                "
                alt
                srcset
                width="120"
                height="120"
                class="rounded-circle"
              />
              <img
                :src="url + participante.foto"
                v-if="
                  participante.genero == 'F' &&
                  participante.foto != null &&
                  !cargando_foto
                "
                alt
                srcset
                width="120"
                height="120"
                class="rounded-circle"
              />
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="cargando_foto && participante != ''"
              >
                <div style="width: 120px; height: 120px">
                  <div
                    class="spinner-border"
                    role="status"
                    style="position: absolute; top: 40%; left: 40%"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <input
                type="file"
                name="foto"
                id="foto-perfil"
                @change="perfilUpload"
                class="d-none"
              />
              <label class="foto-perfil" v-if="participante != ''"
                >Agregar foto</label
              >
            </div>
            <div
              class="position-absolute text-muted"
              style="bottom: 5%; left: 50%"
            >
              <small>dimensión max (500 x 500)</small>
            </div>
          </div>
          <div class="col-lg-8 py-2">
            <div class="form-row">
              <div class="form-group mb-0 col-6">
                <label class="m-0 p-0">Empresa</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  disabled
                  v-model="evaluar.empresa"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group mb-0 col-6">
                <label class="m-0 p-0">Nombre</label>
                <v_select
                  placeholder="Seleccione participante"
                  label="fullname"
                  :options="evaluar.usuarios"
                  v-model="participante"
                  @input="myAction"
                />
              </div>
              <div class="form-group mb-0 col-4">
                <label class="m-0 p-0">Cédula</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  disabled
                  v-model="participante.cedula"
                />
              </div>
              <div class="form-group mb-0 col-2">
                <label class="m-0 p-0">Genero</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  disabled
                  v-model="participante.genero"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group mb-0 col-4">
                <label class="m-0 p-0">Cargo</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  disabled
                  v-model="participante.cargo"
                />
              </div>
              <div class="form-group mb-0 col-6">
                <label class="m-0 p-0">Taller</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  disabled
                  v-model="evaluar.tipo_taller"
                />
              </div>
              <div
                class="form-group mb-0 col-2"
                v-if="evaluar.metodologia == 0"
              >
                <label class="m-0 p-0">Fase</label>
                <select
                  name
                  id
                  class="form-control form-control-sm"
                  v-model="fase"
                >
                  <option value selected disabled>Fase</option>
                  <option value="1">Inicial</option>
                  <option value="2">Final</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <tab
          :participante="participante"
          :estado="evaluar.estado_taller"
          :fase="fase"
          :metodologia="evaluar.metodologia"
          :taller="evaluar.id_taller"
          @cambio="cambioPosicion"
        />
        <examen
          :participante="participante"
          :fase="fase"
          :evaluar="evaluar"
          :posicion="posicion"
          :resultados="resultados"
          v-if="evaluar.metodologia == 0"
        />
        <examenNuevo
          v-else
          :participante="participante"
          :fase="fase"
          :evaluar="evaluar"
          :posicion="posicion"
          :resultados="resultados"
        />
      </div>
      <div class="col-lg-4 content-avatar" v-show="participante && fase">
        <div class="row">
          <div
            class="mt-2 avatar-chart col-lg-12"
            v-if="this.participante.genero == 'M'"
          >
            <img src="@/assets/img/hombre.png" alt srcset />
          </div>
          <div
            class="mt-2 avatar-chart col-lg-12"
            v-if="this.participante.genero == 'F'"
          >
            <img src="@/assets/img/mujer.png" alt srcset />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import v_select from "vue-select";
import "vue-select/dist/vue-select.css";
import tab from "@/components/tabExamen";
import examen from "@/components/examen";
import examenNuevo from "@/components/examenNuevo";
import loading from "@/components/Loading";

export default {
  name: "evaula",
  data() {
    return {
      participante: "",
      posicion: "",
      cargando_foto: false,
      submaster: "",
      fase: "",
      boom: "",
      id: "",
      descargando: false,
      opcion_generar: "",
      archivos_id: [],
      nuevaVentana: "",
      list_videos: [],
      url: process.env.VUE_APP_RUTA_IMG + "fotos/",
      contador: 0,
      path_dropbox: "",
      error: false,
    };
  },
  components: {
    v_select,
    tab,
    examen,
    examenNuevo,
    loading,
  },
  computed: {
    evaluar() {
      return this.$store.getters.getEvaluacion;
    },
    resultados() {
      return this.$store.getters.getResultados;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  watch: {
    fase() {
      if (this.fase != "") {
        this.select_item = "";
      }
    },
    async participante() {
      if (this.participante.id) {
        if (this.nuevaVentana != "") {
          this.$store.dispatch("cambio", {
            id: this.participante.id,
          });
        }
        this.$store.commit("setUsuarioEvaluar", this.participante);
        this.select_item = "";
        await this.createFileDropbox();
      }
    },
    opcion_generar() {
      if (this.opcion_generar == 1 || this.opcion_generar == 2) {
        this.updateCuerpo1(this.opcion_generar);
        this.updateVoz1(this.opcion_generar);
        this.updateFondo1(this.opcion_generar);
      } else if (this.opcion_generar == 3) {
        this.updateCuerpo1(1);
        this.updateVoz1(1);
        this.updateFondo1(1);
        this.updateCuerpo2(2);
        this.updateVoz2(2);
        this.updateFondo2(2);
      }
    },
  },
  methods: {
    async createFileDropbox() {
      var Dropbox = require("dropbox").Dropbox;
      var dbx = new Dropbox({
        fetch: fetch,
        accessToken: process.env.VUE_APP_TOKEN_DROPBOX,
      });
      const { entries } = await dbx.filesListFolder({
        path: `/plataforma holistica`,
      });
      const valid_nombre = entries.find(
        (e) => e.name === this.participante.fullname
      );

      if (!valid_nombre) {
        const { metadata } = await dbx.filesCreateFolderV2({
          path: "/plataforma holistica/" + this.participante.fullname,
        });
        await dbx.filesCreateFolderV2({
          path: `/plataforma holistica/${this.participante.fullname}/${this.evaluar.tipo_taller} - ${this.evaluar.fecha_taller}`,
        });
        const { url } = await dbx.sharingCreateSharedLink({
          path: metadata.path_lower,
          short_url: true,
        });
        let info = {
          user_id: this.participante.id,
          path_dropbox: metadata.path_lower,
          url_dropbox: url,
          token: this.$store.getters.getUserAuth.access_token,
        };
        const url_back = `${process.env.VUE_APP_WEBSERVICE_URL}/dropbox_update`;
        let response = await fetch(url_back, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(info),
        });
        let data = await response.json();
      } else {
        const { entries } = await dbx.filesListFolder({
          path: `/plataforma holistica/${this.participante.fullname}`,
        });
        const nombre_taller = `${this.evaluar.tipo_taller} - ${this.evaluar.fecha_taller}`;
        const valid = entries.find((e) => e.name === nombre_taller);
        if (!valid) {
          await dbx.filesCreateFolderV2({
            path: `/plataforma holistica/${this.participante.fullname}/${this.evaluar.tipo_taller} - ${this.evaluar.fecha_taller}`,
          });
        }
      }
    },

    cambioPosicion(op) {
      this.posicion = op;
    },

    actualizar_item(posicion) {
      this.$set(this.resultados[posicion], "edit", false);
    },

    cancelar() {
      $("#generar").modal("hide");
      this.opcion_generar = "";
    },
    generar() {
      if (this.opcion_generar != "") {
        this.descargando = true;
        let that = this;
        if (this.opcion_generar == 1 || this.opcion_generar == 2) {
          that.$refs.pieCuerpo.chart.dataURI().then((uri) => {
            let datos = {
              imagen: uri,
              user_id: this.participante.id,
              taller_id: this.evaluar.id_taller,
              fase: this.opcion_generar,
              subtipo: 1,
            };
            that.$store.dispatch("setChartFile", datos).then((response) => {
              if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
                that.$refs.pieVoz.chart.dataURI().then((uri) => {
                  let datos = {
                    imagen: uri,
                    user_id: this.participante.id,
                    taller_id: this.evaluar.id_taller,
                    fase: this.opcion_generar,
                    subtipo: 2,
                  };
                  that.$store
                    .dispatch("setChartFile", datos)
                    .then((response) => {
                      if (
                        _.has(response, "STATUS_CODE") &&
                        response.STATUS_CODE == 1
                      ) {
                        that.$refs.pieFondo.chart.dataURI().then((uri) => {
                          let datos = {
                            imagen: uri,
                            user_id: this.participante.id,
                            taller_id: this.evaluar.id_taller,
                            fase: this.opcion_generar,
                            subtipo: 3,
                          };
                          that.$store
                            .dispatch("setChartFile", datos)
                            .then((response) => {
                              if (
                                _.has(response, "STATUS_CODE") &&
                                response.STATUS_CODE == 1
                              ) {
                                let datos = {
                                  user_id: this.participante.id,
                                  taller_id: this.evaluar.id_taller,
                                  opcion: this.opcion_generar,
                                };
                                that.$store
                                  .dispatch("fetchGenerar", datos)
                                  .then((response) => {
                                    if (
                                      _.has(response, "STATUS_CODE") &&
                                      response.STATUS_CODE == 1
                                    ) {
                                      var a = document.createElement("a");
                                      var url =
                                        process.env.VUE_APP_WEBSERVICE_URL +
                                        "/archivo/" +
                                        response.DATA +
                                        "/descargar";
                                      a.href = url;
                                      a.download;
                                      a.target = "_blank";
                                      a.click();
                                      window.URL.revokeObjectURL(url);
                                      this.descargando = false;
                                      const Toast = Swal.mixin({
                                        toast: true,
                                        position: "top-end",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        onOpen: (toast) => {
                                          toast.addEventListener(
                                            "mouseenter",
                                            Swal.stopTimer
                                          );
                                          toast.addEventListener(
                                            "mouseleave",
                                            Swal.resumeTimer
                                          );
                                        },
                                      });

                                      Toast.fire({
                                        icon: "success",
                                        title: "Se genero el pdf",
                                      });
                                    }
                                    this.opcion_generar = "";
                                    this.descargando = false;
                                  });
                              }
                            });
                        });
                      }
                    });
                });
              }
            });
          });
        } else if (this.opcion_generar == 3) {
          that.$refs.pieCuerpo.chart.dataURI().then((uri) => {
            let datos = {
              imagen: uri,
              user_id: this.participante.id,
              taller_id: this.evaluar.id_taller,
              fase: 1,
              subtipo: 1,
            };
            that.$store.dispatch("setChartFile", datos).then((response) => {
              if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
                that.$refs.pieVoz.chart.dataURI().then((uri) => {
                  let datos = {
                    imagen: uri,
                    user_id: this.participante.id,
                    taller_id: this.evaluar.id_taller,
                    fase: 1,
                    subtipo: 2,
                  };
                  that.$store
                    .dispatch("setChartFile", datos)
                    .then((response) => {
                      if (
                        _.has(response, "STATUS_CODE") &&
                        response.STATUS_CODE == 1
                      ) {
                        that.$refs.pieFondo.chart.dataURI().then((uri) => {
                          let datos = {
                            imagen: uri,
                            user_id: this.participante.id,
                            taller_id: this.evaluar.id_taller,
                            fase: 1,
                            subtipo: 3,
                          };
                          that.$store
                            .dispatch("setChartFile", datos)
                            .then((response) => {
                              if (
                                _.has(response, "STATUS_CODE") &&
                                response.STATUS_CODE == 1
                              ) {
                                that.$refs.pieCuerpo2.chart
                                  .dataURI()
                                  .then((uri) => {
                                    let datos = {
                                      imagen: uri,
                                      user_id: this.participante.id,
                                      taller_id: this.evaluar.id_taller,
                                      fase: 2,
                                      subtipo: 1,
                                    };
                                    that.$store
                                      .dispatch("setChartFile", datos)
                                      .then((response) => {
                                        if (
                                          _.has(response, "STATUS_CODE") &&
                                          response.STATUS_CODE == 1
                                        ) {
                                          that.$refs.pieVoz2.chart
                                            .dataURI()
                                            .then((uri) => {
                                              let datos = {
                                                imagen: uri,
                                                user_id: this.participante.id,
                                                taller_id: this.evaluar
                                                  .id_taller,
                                                fase: 2,
                                                subtipo: 2,
                                              };
                                              that.$store
                                                .dispatch("setChartFile", datos)
                                                .then((response) => {
                                                  if (
                                                    _.has(
                                                      response,
                                                      "STATUS_CODE"
                                                    ) &&
                                                    response.STATUS_CODE == 1
                                                  ) {
                                                    that.$refs.pieFondo2.chart
                                                      .dataURI()
                                                      .then((uri) => {
                                                        let datos = {
                                                          imagen: uri,
                                                          user_id: this
                                                            .participante.id,
                                                          taller_id: this
                                                            .evaluar.id_taller,
                                                          fase: 2,
                                                          subtipo: 3,
                                                        };
                                                        that.$store
                                                          .dispatch(
                                                            "setChartFile",
                                                            datos
                                                          )
                                                          .then((response) => {
                                                            if (
                                                              _.has(
                                                                response,
                                                                "STATUS_CODE"
                                                              ) &&
                                                              response.STATUS_CODE ==
                                                                1
                                                            ) {
                                                              let datos = {
                                                                user_id: this
                                                                  .participante
                                                                  .id,
                                                                taller_id: this
                                                                  .evaluar
                                                                  .id_taller,
                                                                opcion: this
                                                                  .opcion_generar,
                                                              };
                                                              that.$store
                                                                .dispatch(
                                                                  "fetchGenerar",
                                                                  datos
                                                                )
                                                                .then(
                                                                  (
                                                                    response
                                                                  ) => {
                                                                    if (
                                                                      _.has(
                                                                        response,
                                                                        "STATUS_CODE"
                                                                      ) &&
                                                                      response.STATUS_CODE ==
                                                                        1
                                                                    ) {
                                                                      var a = document.createElement(
                                                                        "a"
                                                                      );
                                                                      var url =
                                                                        process
                                                                          .env
                                                                          .VUE_APP_WEBSERVICE_URL +
                                                                        "/archivo/" +
                                                                        response.DATA +
                                                                        "/descargar";
                                                                      a.href = url;
                                                                      a.download;
                                                                      a.target =
                                                                        "_blank";
                                                                      a.click();
                                                                      window.URL.revokeObjectURL(
                                                                        url
                                                                      );
                                                                      this.descargando = false;
                                                                      const Toast = Swal.mixin(
                                                                        {
                                                                          toast: true,
                                                                          position:
                                                                            "top-end",
                                                                          showConfirmButton: false,
                                                                          timer: 3000,
                                                                          timerProgressBar: true,
                                                                          onOpen: (
                                                                            toast
                                                                          ) => {
                                                                            toast.addEventListener(
                                                                              "mouseenter",
                                                                              Swal.stopTimer
                                                                            );
                                                                            toast.addEventListener(
                                                                              "mouseleave",
                                                                              Swal.resumeTimer
                                                                            );
                                                                          },
                                                                        }
                                                                      );
                                                                      Toast.fire(
                                                                        {
                                                                          icon:
                                                                            "success",
                                                                          title:
                                                                            "Se genero el pdf",
                                                                        }
                                                                      );
                                                                    }
                                                                    this.opcion_generar =
                                                                      "";
                                                                    this.descargando = false;
                                                                  }
                                                                );
                                                            }
                                                          });
                                                      });
                                                  }
                                                });
                                            });
                                        }
                                      });
                                  });
                              }
                            });
                        });
                      }
                    });
                });
              }
            });
          });
        }
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Debes seleccionar el tipo de estado",
        });
        return false;
      }
    },

    open_perfil() {
      $("#foto-perfil").click();
    },

    async perfilUpload(e) {
      if (this.participante != "") {
        let posicion = e.target.files[0].name.indexOf(".");
        let extension = e.target.files[0].name.substring(posicion + 1);
        let reader = new FileReader();
        let valido = ["png", "jpg", "jpeg"];
        let that = this;
        reader.readAsDataURL(e.target.files[0]);
        reader.addEventListener("load", function () {
          var image = new Image();
          image.src = reader.result;
          image.addEventListener("load", function () {
            if (image.width > 500 && image.height > 500) {
              that.error = true;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "info",
                title: "La medida de la imagen debe ser de 500x500",
              });
              $("#foto-perfil").val();
              return false;
            }
            if (/\.(jpg|png|jpeg)$/i.test(e.target.files[0].name)) {
              that.cargando_foto = true;

              that.$store
                .dispatch("uploadFileCliente", {
                  archivo: e.target.files[0],
                  user_id: that.participante.id,
                })
                .then((resp) => {
                  if (resp.STATUS_CODE == 1) {
                    that.participante.foto = resp.DATA;
                    that.cargando_foto = true;
                    that.$store.dispatch("cambio", {
                      id: that.participante.id,
                      taller_id: that.$route.params.id,
                    });
                    that.cargando_foto = false;
                  }
                });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "No es una imagen.",
              });
              that.cargando_foto = false;
            }
          });
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "info",
          title: "Debes seleccionar el participante para poder cargar la foto!",
        });
      }
    },
    myAction: function (e) {
      if (!e) {
        this.participante = "";
      }
    },
  },
  async mounted() {
    let host = window.location.origin;
    await this.$store.dispatch("fetchResultado", this.$route.params.id);
    await this.$store.dispatch("fectEvaluacion", this.$route.params.id);
    this.nuevaVentana = window.open(
      host + "/#/presentacion/view/" + this.$route.params.id,
      "" + this.participante.fullname + "",
      "width=1200, height=753, resizable=true, menubar=yes"
    );
    this.nuevaVentana.focus();
  },
};
</script>
<style lang="css">
.foto-perfil {
  position: absolute;
  right: 20px;
  bottom: 0;
  font-size: 14px;
}
</style>
