<template>
  <div
    class="d-flex"
    :class="
      metodologia == 1 ? 'justify-content-end' : 'justify-content-between'
    "
  >
    <ul class="nav" v-show="participante && fase && metodologia == 0">
      <li
        class="nav-item cursor-pointer"
        :class="posicion == 'forma' ? 'posicion-activo' : ''"
      >
        <p class="nav-link mb-0" @click="open_card('forma')">Forma</p>
      </li>
      <li
        class="nav-item cursor-pointer"
        :class="posicion == 'fondo' ? 'posicion-activo' : ''"
      >
        <p class="nav-link mb-0" @click="open_card('fondo')">Fondo</p>
      </li>
    </ul>
    <div>
      <div class="bg-info float-left mr-2 mt-2 rounded cursor-pointer">
        <p @click="proyectVocero()" class="py-1 px-1 mb-0 text-white">
          Proyectar Vocero
        </p>
      </div>
      <div class="float-left mr-2 mt-2 rounded cursor-pointer">
        <div class="dropdown">
          <button
            class="btn btn-primary dropdown-toggle btn-sm"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Ranking
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click.prevent="openRanking()"
              >Proyectar</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="generarRanking()"
              v-if="!generar_ranking"
              >Descargar</a
            >
            <a class="dropdown-item" v-else>
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              Descargando
            </a>
          </div>
        </div>
      </div>
      <div class="float-left mr-2 mt-2 rounded cursor-pointer">
        <div class="dropdown">
          <button
            class="btn btn-dark dropdown-toggle btn-sm"
            type="button"
            id="dropdownMenuButton2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Opciones taller
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <a class="dropdown-item" href="#" @click.prevent="openvideo()"
              >Subir información</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="generarPdf()"
              v-if="!generar_pdf"
              >Generar Evaluación</a
            >
            <a class="dropdown-item" v-else>
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              Generando Evaluación
            </a>
            <a class="dropdown-item" href="#" @click.prevent="finalizar()"
              >Cerrar Evaluación</a
            >
          </div>
        </div>
      </div>
    </div>
    <!--Modal video -->
    <modalVideo ref="modalVideo" />
  </div>
</template>
<script>
import modalVideo from "./modalUpload";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  props: ["participante", "fase", "estado", "metodologia", "taller"],
  data() {
    return {
      posicion: "",
      nuevaVentana: "",
      generar_pdf: false,
      generar_ranking: false,
    };
  },
  components: {
    modalVideo,
  },
  computed: {
    evaluar() {
      return this.$store.getters.getEvaluacion;
    },
  },
  methods: {
    open_card(posicion) {
      this.posicion = posicion;
      this.$emit("cambio", posicion);
    },

    openvideo() {
      this.$refs.modalVideo.toggle();
    },

    openRanking() {
      if (this.evaluar.estado_taller == 2 && this.metodologia == 0) {
        let host = window.location.origin;
        this.nuevaVentana = window.open(
          host + "/#/presentacion/view/" + this.$route.params.id + "/ranking",
          "" + this.participante.fullname + "",
          "width=1200, height=753, resizable=true, menubar=yes"
        );
        this.nuevaVentana.focus();
      } else {
        let host = window.location.origin;
        this.nuevaVentana = window.open(
          host + "/#/presentacion/view/" + this.$route.params.id + "/ranking",
          "" + this.participante.fullname + "",
          "width=1200, height=753, resizable=true, menubar=yes"
        );
        this.nuevaVentana.focus();
      }
    },

    async generarPdf() {
      this.generar_pdf = true;
      let token = JSON.parse(atob(localStorage.getItem("usuario")));
      const response = await fetch(
        `${process.env.VUE_APP_WEBSERVICE_URL}/informe/${this.taller}/${this.participante.id}/1/generar?token=${token.access_token}`
      );
      const { STATUS_CODE, DATA } = await response.json();
      if (STATUS_CODE) {
        var a = document.createElement("a");
        var url =
          process.env.VUE_APP_WEBSERVICE_URL +
          "/archivo/" +
          DATA +
          "/descargar";
        a.href = url;
        a.download;
        a.target = "_blank";
        a.click();
        window.URL.revokeObjectURL(url);
      }
      this.generar_pdf = false;
    },

    async generarRanking() {
      this.generar_ranking = true;
      let token = JSON.parse(atob(localStorage.getItem("usuario")));
      const response = await fetch(
        `${process.env.VUE_APP_WEBSERVICE_URL}/ranking/${this.taller}/generar/pdf?token=${token.access_token}`
      );
      const { STATUS_CODE, DATA } = await response.json();
      if (STATUS_CODE) {
        var a = document.createElement("a");
        var url =
          process.env.VUE_APP_WEBSERVICE_URL +
          "/archivo/" +
          DATA +
          "/descargar";
        a.href = url;
        a.download;
        a.target = "_blank";
        a.click();
        window.URL.revokeObjectURL(url);
      }
      this.generar_ranking = false;
    },
    finalizar() {
      if (this.evaluar.estado_taller == 2 && this.metodologia == 0) {
        this.$store
          .dispatch("cambiarEstado", this.evaluar.id_taller)
          .then((response) => {
            if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
              this.$store.dispatch("fectEvaluacion", this.evaluar.id_taller);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "Se finalizo el taller " + this.evaluar.tipo_taller,
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: response.MESSAGE,
              });
            }
          });
      } else {
        this.$store
          .dispatch("cambiarEstado", this.evaluar.id_taller)
          .then((response) => {
            if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
              this.$store.dispatch("fectEvaluacion", this.evaluar.id_taller);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "Se finalizo el taller " + this.evaluar.tipo_taller,
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: response.MESSAGE,
              });
            }
          });
      }
    },
    proyectVocero() {
      let host = window.location.origin;
      this.nuevaVentana = window.open(
        host + "/#/presentacion/view/" + this.$route.params.id,
        "" + this.participante.fullname + "",
        "width=1200, height=753, resizable=true, menubar=yes"
      );
      this.nuevaVentana.focus();
      setTimeout(() => {
        this.$store.dispatch("cambio", {
          id: this.participante.id,
        });
      }, 2000);
    },
  },
};
</script>