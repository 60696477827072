<template>
  <section>
    <div class="mt-3" v-show="participante && posicion == 'forma' && fase">
      <div class="card shadow mb-2">
        <div
          class="card-header py-1 bg-card cursor-pointer"
          @click="open_toggle('cuerpo')"
        >
          Cuerpo
        </div>
        <div class="card-body" :class="cuerpo ? 'mostrar' : 'esconder'">
          <div class="row p-0 m-0">
            <div class="col-lg-2 p-0">
              <ul class="p-0">
                <li
                  class="list-items cursor-pointer"
                  v-for="e in evaluar.aspectos"
                  :key="e.id"
                  v-if="e.subtipo == 1"
                >
                  <label
                    class="m-0 p-1 cursor-pointer"
                    @click="desplegar('cuerpo', e.id)"
                    >{{ e.nombre }}</label
                  >
                  <span
                    class="float-right bg-light mt-1 mr-1 px-2 rounded-circle cursor-pointer"
                    @click="opendefinicion(e.definicion)"
                    >?</span
                  >
                  <ul
                    class="p-0 mb-2"
                    :class="
                      master == 'cuerpo' && e.id == submaster
                        ? 'sub-mostrar'
                        : 'sub-esconder'
                    "
                  >
                    <li
                      class="sub-items p-1"
                      :class="
                        sub.id == select_item && e.id == principal
                          ? 'sub-items-activo'
                          : ''
                      "
                      v-for="sub in e.subaspectos"
                      :key="sub.id"
                    >
                      <p class="m-0 pl-1" @click="item(sub.id, e.id, sub, 1)">
                        {{ sub.nombre }}
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              class="col-lg-10"
              v-for="(j, index) in resultados"
              :key="index"
              v-show="
                j.subappearance_id == select_item &&
                j.user_id == participante.id &&
                j.fase == fase &&
                j.appearence_id == submaster &&
                j.subtipo == 1
              "
            >
              <div class="evaluacion">
                <p class="m-0 pl-1">Evaluación</p>
                <p class="p-1" v-if="!j.edit">{{ j.evaluacion }}</p>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-else
                  v-model="j.evaluacion"
                ></textarea>
              </div>
              <div class="calificacion row m-0 mb-2">
                <div
                  class="align-content-center col-lg-2 d-flex justify-content-center m-0 p-0 px-1 py-1"
                >
                  <p class="m-0">Calificación</p>
                </div>
                <div class="m-0 col-lg-10 p-0 d-flex align-items-center">
                  <label class="mx-1 mb-0" v-for="i in 10" :key="i">
                    {{ i }}
                    <input
                      type="radio"
                      id="1"
                      :value="i"
                      v-model="j.puntaje"
                      :disabled="evaluar.estado_taller != 2 ? true : false"
                    />
                  </label>
                </div>
              </div>
              <div class="ejercicios">
                <p class="m-0 pl-1">Ejercicios de fortalecimiento</p>
                <select class="form-control mb-1" v-model="j.seleccionado">
                  <option value selected disabled>
                    Seleccione un ejercicio recomendado
                  </option>
                  <option
                    v-for="(m, index) in ejercicios"
                    :key="index"
                    :value="m"
                  >
                    {{ m }}
                  </option>
                </select>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-end"
                v-if="!j.edit && evaluar.estado_taller == 2"
              >
                <button
                  class="m-0 mr-1 btn btn-sm btn-secondary"
                  @click="edit_item(index)"
                >
                  Editar
                </button>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="guardar(1, j)"
                  v-if="!guardar_calificacion"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando.....
                </button>
              </div>
              <div class="col-lg-12 d-flex justify-content-end mt-2" v-else>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="actualizar_item(index)"
                >
                  Actualizar
                </button>
              </div>
            </div>
            <div class="col-lg-12">
              <strong>Tu puntaje es</strong>
              : {{ inversa_puntaje_cuerpo() }}
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow">
        <div
          class="card-header py-1 bg-card cursor-pointer"
          @click="open_toggle('voz')"
        >
          Voz
        </div>
        <div class="card-body" :class="voz ? 'mostrar' : 'esconder'">
          <div class="row p-0 m-0">
            <div class="col-lg-2 p-0">
              <ul class="p-0">
                <li
                  class="list-items"
                  v-for="e in evaluar.aspectos"
                  :key="e.id"
                  v-if="e.subtipo == 2"
                >
                  <label
                    class="m-0 p-1 cursor-pointer"
                    @click="desplegar('voz', e.id)"
                    >{{ e.nombre }}</label
                  >
                  <span
                    class="float-right bg-light px-2 mt-1 mr-1 rounded-circle efecto cursor-pointer"
                    @click="opendefinicion(e.definicion)"
                    >?</span
                  >
                  <ul
                    class="p-0 mb-2"
                    :class="
                      master == 'voz' && e.id == submaster
                        ? 'sub-mostrar'
                        : 'sub-esconder'
                    "
                  >
                    <li
                      class="sub-items p-1"
                      v-for="sub in e.subaspectos"
                      :key="sub.id"
                      :class="
                        sub.id == select_item && e.id == principal
                          ? 'sub-items-activo'
                          : ''
                      "
                    >
                      <p class="m-0 pl-1" @click="item(sub.id, e.id, sub, 2)">
                        {{ sub.nombre }}
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              class="col-lg-10"
              v-for="(j, index) in resultados"
              :key="index"
              v-show="
                j.subappearance_id == select_item &&
                j.user_id == participante.id &&
                j.fase == fase &&
                j.appearence_id == submaster &&
                j.subtipo == 2
              "
            >
              <div class="evaluacion">
                <p class="m-0 pl-1">Evaluación</p>
                <p class="p-1" v-if="!j.edit">{{ j.evaluacion }}</p>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-else
                  v-model="j.evaluacion"
                ></textarea>
              </div>
              <div class="calificacion row m-0 mb-2">
                <div
                  class="align-content-center col-lg-2 d-flex justify-content-center m-0 p-0 px-1 py-1"
                >
                  <p class="m-0">Calificación</p>
                </div>
                <div class="m-0 col-lg-10 p-0 d-flex align-items-center">
                  <label class="mx-1 mb-0" v-for="i in 10" :key="i">
                    {{ i }}
                    <input
                      type="radio"
                      id="1"
                      :value="i"
                      v-model="j.puntaje"
                      :disabled="evaluar.estado_taller != 2 ? true : false"
                    />
                  </label>
                </div>
              </div>
              <div class="ejercicios">
                <p class="m-0 pl-1">Ejercicios de fortalecimiento</p>
                <select class="form-control mb-1" v-model="j.seleccionado">
                  <option selected disabled value="0">
                    Seleccione un ejercicio recomendado
                  </option>
                  <option
                    v-for="(m, index) in ejercicios"
                    :key="index"
                    :value="m"
                  >
                    {{ m }}
                  </option>
                </select>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-end"
                v-if="!j.edit && evaluar.estado_taller == 2"
              >
                <button
                  class="m-0 mr-1 btn btn-sm btn-secondary"
                  @click="edit_item(index)"
                >
                  Editar
                </button>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="guardar(2, j)"
                  v-if="!guardar_calificacion"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando.....
                </button>
              </div>
              <div class="col-lg-12 d-flex justify-content-end mt-2" v-else>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="actualizar_item(index)"
                >
                  Actualizar
                </button>
              </div>
            </div>
            <div class="col-lg-12">
              <strong>Tu puntaje es</strong>
              : {{ inversa_puntaje_voz() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3" v-show="participante && posicion == 'fondo' && fase">
      <div class="card shadow">
        <div
          class="card-header py-1 bg-card cursor-pointer"
          @click="open_toggle('fondo')"
        >
          Fondo
        </div>
        <div class="card-body" :class="fondo ? 'mostrar' : 'esconder'">
          <div class="row p-0 m-0">
            <div class="col-lg-3 p-0">
              <ul class="p-0">
                <li
                  class="list-items"
                  v-for="e in evaluar.aspectos"
                  :key="e.id"
                  v-if="e.subtipo == 3"
                >
                  <label
                    class="m-0 p-1 cursor-pointer"
                    @click="desplegar('fondo', e.id)"
                    >{{ e.nombre }}</label
                  >
                  <span
                    class="float-right bg-light px-2 mt-1 mr-1 rounded-circle efecto cursor-pointer"
                    @click="opendefinicion(e.definicion)"
                    >?</span
                  >
                  <ul
                    class="p-0 mb-2"
                    :class="
                      master == 'fondo' && e.id == submaster
                        ? 'sub-mostrar'
                        : 'sub-esconder'
                    "
                  >
                    <li
                      class="sub-items p-1"
                      v-for="sub in e.subaspectos"
                      :key="sub.id"
                      :class="
                        sub.id == select_item && e.id == principal
                          ? 'sub-items-activo'
                          : ''
                      "
                    >
                      <p class="m-0 pl-1" @click="item(sub.id, e.id, sub, 3)">
                        {{ sub.nombre }}
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              class="col-lg-9"
              v-for="(j, index) in resultados"
              :key="index"
              v-show="
                j.subappearance_id == select_item &&
                j.user_id == participante.id &&
                j.fase == fase &&
                j.appearence_id == submaster &&
                j.subtipo == 3
              "
            >
              <div class="evaluacion">
                <p class="m-0 pl-1">Evaluación</p>
                <p class="p-1" v-if="!j.edit">{{ j.evaluacion }}</p>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-else
                  v-model="j.evaluacion"
                ></textarea>
              </div>
              <div class="calificacion row m-0 mb-2">
                <div
                  class="align-content-center col-lg-2 d-flex justify-content-center m-0 p-0 px-1 py-1"
                >
                  <p class="m-0">Calificación</p>
                </div>
                <div class="m-0 col-lg-10 p-0 d-flex align-items-center">
                  <label class="mx-1 mb-0" v-for="i in 10" :key="i">
                    {{ i }}
                    <input
                      type="radio"
                      id="1"
                      :value="i"
                      v-model="j.puntaje"
                      :disabled="evaluar.estado_taller != 2 ? true : false"
                    />
                  </label>
                </div>
              </div>
              <div class="ejercicios">
                <p class="m-0 pl-1">Ejercicios de fortalecimiento</p>
                <select class="form-control mb-1" v-model="j.seleccionado">
                  <option selected disabled>
                    Seleccione un ejercicio recomendado
                  </option>
                  <option
                    v-for="(m, index) in ejercicios"
                    :key="index"
                    :value="m"
                  >
                    {{ m }}
                  </option>
                </select>
              </div>
              <div
                class="col-lg-12 d-flex justify-content-end"
                v-if="!j.edit && evaluar.estado_taller == 2"
              >
                <button
                  class="m-0 mr-1 btn btn-sm btn-secondary"
                  @click="edit_item(index)"
                >
                  Editar
                </button>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="guardar(3, j)"
                  v-if="!guardar_calificacion"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-sm btn-success"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando.....
                </button>
              </div>
              <div class="col-lg-12 d-flex justify-content-end mt-2" v-else>
                <button
                  class="m-0 btn btn-sm btn-success"
                  @click="actualizar_item(index)"
                >
                  Actualizar
                </button>
              </div>
            </div>
            <div class="col-lg-12">
              <strong>Tu puntaje es</strong>
              : {{ inversa_puntaje_fondo() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Definicion -->
    <modalDefinicion :definicion="definicion" ref="modalDefinicion" />
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import modalDefinicion from "./modalDefinicion";
export default {
  props: ["participante", "fase", "posicion", "evaluar", "resultados"],
  data() {
    return {
      select_item: "",
      principal: "",
      cuerpo: false,
      voz: false,
      fondo: false,
      guardar_calificacion: false,
      master: "",
      submaster: "",
      definicion: "",
      ejercicios: [],
    };
  },
  components: {
    modalDefinicion,
  },
  watch: {
    participante() {
      this.submaster = "";
      this.select_item = "";
      this.principal = "";
    },
  },
  methods: {
    desplegar(posicion, principal) {
      this.master = posicion;
      this.submaster = principal;
      this.select_item = "";
    },

    actualizar_item(posicion) {
      this.$set(this.resultados[posicion], "edit", false);
    },

    open_toggle(posicion) {
      this.boom = posicion;
      switch (posicion) {
        case "cuerpo":
          this.voz = false;
          this.cuerpo = !this.cuerpo;
          this.fondo = false;
          this.select_item = "";
          this.principal = "";
          this.master = "";
          this.submaster = "";
          break;
        case "voz":
          this.cuerpo = false;
          this.voz = !this.voz;
          this.fondo = false;
          this.select_item = "";
          this.principal = "";
          this.master = "";
          this.submaster = "";
          break;
        case "fondo":
          this.cuerpo = false;
          this.voz = false;
          this.fondo = !this.fondo;
          this.select_item = "";
          this.principal = "";
          this.master = "";
          this.submaster = "";
          break;

        default:
          break;
      }
    },

    inversa_puntaje_cuerpo() {
      let suma = 0;
      let resultado = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (
          element.user_id == this.participante.id &&
          element.fase == this.fase &&
          element.subtipo == 1
        ) {
          if (element.puntaje != "") {
            suma += element.puntaje;
          }
        }
      }
      if (suma != 0) {
        resultado = (suma * 35) / 100;
        return resultado.toFixed(2);
      }
      return 0;
    },

    inversa_puntaje_voz() {
      let suma = 0;
      let resultado = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (
          element.user_id == this.participante.id &&
          element.fase == this.fase &&
          element.subtipo == 2
        ) {
          if (element.puntaje != "") {
            suma += element.puntaje;
          }
        }
      }
      if (suma != 0) {
        resultado = (suma * 15) / 100;
        return resultado.toFixed(2);
      }
      return 0;
    },

    inversa_puntaje_fondo() {
      let suma = 0;
      let resultado = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (
          element.user_id == this.participante.id &&
          element.fase == this.fase &&
          element.subtipo == 3
        ) {
          if (element.puntaje != "") {
            suma += element.puntaje;
          }
        }
      }
      if (suma != 0) {
        resultado = (suma * 50) / 100;
        return resultado.toFixed(2);
      }
      return 0;
    },

    edit_item(posicion) {
      this.$set(this.resultados[posicion], "edit", true);
    },

    item(posicion, principal, data, num) {
      this.principal = principal;
      this.select_item = posicion;
      this.ejercicios = JSON.parse(data.ejercicios);
      const info = {
        user_id: this.participante.id,
        subappearance_id: this.select_item,
        fase: this.fase,
        appearence_id: this.submaster,
        subtipo: num,
        ejercicios: JSON.parse(data.ejercicios),
        puntaje: "",
        evaluacion: data.evaluacion,
      };
      this.$store.commit("addResultado", info);
    },

    opendefinicion(definicion) {
      this.definicion = definicion;
      this.$refs.modalDefinicion.toggle();
    },

    guardar(index, dato) {
      this.guardar_calificacion = true;
      let datos = {
        evaluacion: dato.evaluacion,
        fase: dato.fase != undefined ? dato.fase : this.fase,
        subtipo: dato.subtipo != undefined ? dato.subtipo : index,
        mejora: dato.seleccionado,
        aspecto_id: dato.appearence_id,
        recomendacion: dato.seleccionado,
        puntaje: dato.puntaje,
        subaspecto_id: dato.subappearance_id,
        usuario_id:
          dato.user_id != undefined ? dato.user_id : this.participante.id,
        taller_id:
          dato.workshop_id != undefined
            ? dato.workshop_id
            : this.evaluar.id_taller,
        id: dato.id != undefined ? dato.id : "",
      };
      if (
        datos.evaluacion == "" ||
        datos.mejora == "" ||
        datos.mejora == undefined ||
        datos.recomendacion == "" ||
        datos.puntaje == ""
      ) {
        this.guardar_calificacion = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Hay datos sin llenar!",
        });
      } else {
        if (datos.id == "") {
          let element = this;
          this.$store.dispatch("setResultado", datos).then((response) => {
            if (response.STATUS_CODE == 1) {
              for (let index = 0; index < element.resultados.length; index++) {
                const info = element.resultados[index];
                if (
                  info.subappearance_id == response.DATA.subappearance_id &&
                  info.user_id == response.DATA.user_id &&
                  info.appearence_id == response.DATA.appearence_id
                ) {
                  Object.assign(this.resultados[index], {
                    id: response.DATA.id,
                  });
                }
              }
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Se guardo la calificacion!",
              });
            } else {
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Erro al guardar la calificacion!",
              });
            }
          });
        } else {
          let element = this;
          datos._method = "put";
          this.$store.dispatch("updateResultado", datos).then((response) => {
            if (response.STATUS_CODE == 1) {
              for (let index = 0; index < element.resultados.length; index++) {
                const info = element.resultados[index];
                if (info.id == response.DATA.id) {
                  Object.assign({}, this.resultados[index], {
                    id: response.DATA.id,
                    evaluacion: response.DATA.evaluacion,
                    seleccionado: response.DATA.mejora,
                    puntaje: response.DATA.puntaje,
                    subtipo: response.DATA.subtipo,
                    fase: response.DATA.fase,
                    subappearance_id: response.DATA.subappearance_id,
                    user_id: response.DATA.user_id,
                    appearence_id: response.DATA.appearence_id,
                    workshop_id: response.DATA.workshop_id,
                    mejora: info.mejora,
                  });
                  this.guardar_calificacion = false;
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });

                  Toast.fire({
                    icon: "success",
                    title: "Se actualizo la calificacion!",
                  });
                }
              }
            } else {
              this.guardar_calificacion = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Error al actualizar la calificacion!",
              });
            }
          });
        }
      }
    },
  },
};
</script>